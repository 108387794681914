import React, { useEffect, useRef, useState } from "react";
import Moment from "react-moment";
import Styles from "./_chat.module.css";
import { HiSpeakerphone } from "react-icons/hi";
import { IoCheckmarkDoneSharp, IoSendSharp } from "react-icons/io5";
import { FcCheckmark } from "react-icons/fc";
import Linkify from "react-linkify";
import linkifyHtml from "linkify-html";
import "./styles.css";
import { FiSend } from "react-icons/fi";
import styles from "./Modal.module.css";
import useDownloader from "react-use-downloader";
import { IoIosShareAlt } from "react-icons/io";
import { Rings } from "react-loader-spinner";
import Select from "react-select";
import { Modal, Dropdown, IconButton } from "rsuite";
import AxiosInstance from "../../../api/AxiosInstance";
import { toast } from "react-toastify";
import { GiWideArrowDunk } from "react-icons/gi";
import { FaAngleDown } from "react-icons/fa";
import { socket } from "../../../ioContext/socketIo";
import { useNavigate } from "react-router-dom";
import handleError from "../ErrorHandler";
import fetchImageUrl from "../../functions/FetchFileWithToken";
import {
  Modal as MuiModal,
  Box,
  IconButton as MuiIconButton,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
const styleModal2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  maxHeight: "90vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  borderRadius: "10px",
  outline: "2px solid #f57c00",
};
const Message = ({
  message,
  own,
  particularStudent,
  index,
  loadingmsg,
  setForward,
  forward,
  onClick,
  selectedMessages,
  reply,
  setReply,
  setSelectedMessages,
}) => {
  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  let navigate = useNavigate();

  const [previewImage, setPreviewImage] = useState("");
  const [open, setOpen] = React.useState(false);
  const [openmodal, setOpenmodal] = React.useState(false);
  let [batchData, setBatchData] = useState([]);
  let [groupData, setGroupData] = useState([]);
  let [studentData, setStudentData] = useState([]);
  let [searchCleared, setSearchCleared] = useState(false);

  let [student, setStudent] = useState([]);
  let [batchselected, setBatchSelected] = useState(false);
  let [groupselected, setGroupSelected] = useState(false);
  let [studentselected, setStudentSelected] = useState(false);
  const [backgroundColor1, setBackgroundColor1] = useState("");
  const [SelectedBatches, setSelectedBatches] = useState([]);
  const [SelectedGroups, setSelectedGroups] = useState([]);
  const [SelectedStudents, setSelectedStudents] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  let [forwardUser, setForwardUser] = useState([]);
  let [userselected, setuserSelected] = useState(false);
  const [SelectedUsers, setSelectedUsers] = useState([]);
  const { download } = useDownloader();

  // poll
  let [pollData, setPollData] = useState([]);
  let [loading, setLoading] = useState(false);
  let [maindata, setMainData] = useState([]);
  let [viewPollData, setViewPollData] = useState([]);
  let [viewOptions, setViewOptions] = useState([]);
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [openPoll, setOpenPoll] = React.useState(false);
  const [openPollView, setOpenPollView] = React.useState(false);
  const [openmodal2, setOpenmodal2] = React.useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [dragging, setDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [offsetX, setOffsetX] = useState(0);
  const [offsetY, setOffsetY] = useState(0);
  const imgRef = useRef(null);

  const handleZoomIn = () => {
    setZoomLevel(zoomLevel + 0.1);
  };

  const handleZoomOut = () => {
    setZoomLevel(zoomLevel - 0.1);
  };
  const handleMouseDown = e => {
    if (zoomLevel > 1) {
      setDragging(true);
      setStartX(e.pageX - offsetX);
      setStartY(e.pageY - offsetY);
    }
  };

  const handleMouseMove = e => {
    if (dragging) {
      e.preventDefault();
      const x = e.pageX - startX;
      const y = e.pageY - startY;
      setOffsetX(x);
      setOffsetY(y);
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  const handleMouseLeave = () => {
    setDragging(false);
  };

  const handleCloseModal = () => {
    setOpenmodal(false);
    setSelectedBatches([]);
    setSelectedGroups([]);
    setSelectedStudents([]);
    setBatchData([]);
    setGroupData([]);
    setStudentData([]);
    setSelectedUsers([]);
    setReply(false);
    setForward(false);
    setSelectedMessages([]);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen2 = () => {
    setOpenmodal2(true);
  };
  const handleClose2 = () => {
    setOpenmodal2(false);
  };
  const handleOpenpoll = () => setOpenPoll(true);
  const handleClosepoll = () => {
    setOpenPoll(false);
    // setSelectedLabels([]);
  };
  const handleOpenpollView = () => setOpenPollView(true);
  const handleClosepollView = () => {
    setOpenPollView(false);
  };

  const openImagePreview = imageSrc => {
    setPreviewImage(imageSrc);
  };

  let handleImage = e => {
    handleOpen(true);
    openImagePreview(e);
  };

  const [imageUrl, setImageUrl] = useState("");

  let [replyimgUrl, setReplyImageUrl] = useState("");

  useEffect(() => {
    const fetchUrl = async () => {
      if (
        message?.replayback &&
        Object.keys(message?.replayback).length > 0 &&
        message?.replayback?.file !== "" &&
        message?.replayback?.file != undefined
      ) {
        const fileData = await fetchImageUrl(
          message?.replayback?.file[0]?.path,
          user?.TOKEN
        );
        if (fileData) {
          setReplyImageUrl(fileData);
        }
      }
    };

    fetchUrl();
  }, [message]);

  useEffect(() => {
    const fetchUrl = async () => {
      if (message?.messages?.files !== undefined) {
        const fileData = await fetchImageUrl(
          message?.messages?.files[0]?.path,
          user?.TOKEN
        );
        if (fileData) {
          setImageUrl(fileData);
        }
      }

      if (message?.status && message?.status?.message?.files != undefined) {
        const fileData = await fetchImageUrl(
          message?.status?.message?.files[0]?.path,
          user?.TOKEN
        );
        if (fileData) {
          setImageUrl(fileData);
        }
      }

      // if (
      //   message?.replayback &&
      //   Object.keys(message?.replayback).length > 0 &&
      //   message?.replayback?.file !== "" &&
      //   message?.replayback?.file != undefined
      // ) {
      //   const fileData = await fetchImageUrl(
      //     message?.replayback?.file[0]?.path,
      //     user?.TOKEN
      //   );
      //   if (fileData) {
      //     setImageUrl(fileData);
      //   }
      // }
    };

    fetchUrl();
  }, [message]);

  useEffect(() => {
    const isMessageSelected = selectedMessages.some(
      msg =>
        msg?.messages?.text === message?.messages?.text && msg?.index === index
    );

    setBackgroundColor1(isMessageSelected ? "rgb(0 0 0 / 12%)" : "");
  }, [selectedMessages, message, index]);

  useEffect(() => {
    setBackgroundColor1("");
  }, [particularStudent || reply]);

  let HandleReply = () => {
    setReply(true);
    setForward(false);
  };
  let HandleForward = () => {
    setForward(true);
    setReply(false);
  };

  const handleClick = () => {
    const isMessageSelected = selectedMessages.some(
      msg => msg.messages.text === message.messages.text && msg.index === index
    );
    if (
      message?.poll === null &&
      selectedMessages?.length < 1 &&
      forward === false
    ) {
      setBackgroundColor1(backgroundColor1 === "" ? "rgb(0 0 0 / 12%)" : "");
      const modifiedMessage = { ...message, index, isMessageSelected };
      onClick(modifiedMessage);
    } else if (message?.poll === null && forward === true) {
      setBackgroundColor1(backgroundColor1 === "" ? "rgb(0 0 0 / 12%)" : "");
      const modifiedMessage = { ...message, index, isMessageSelected };
      onClick(modifiedMessage);
    } else if (
      forward === false &&
      reply === false &&
      selectedMessages?.length === 1
    ) {
      setBackgroundColor1(backgroundColor1 === "");
      setSelectedMessages([]);
    }
  };

  let FetchBatch = async () => {
    setBatchSelected(true);
    setOpenmodal(true);

    async function fetchData() {
      await AxiosInstance.get(`users/batches`, {
        headers: {
          Authorization: `Bearer ${user?.TOKEN}`,
        },
      })
        .then(x => {
          let data = x?.data?.BatchsDetails;
          //(data);
          const uniqueArray = data.filter((obj, index, arr) => {
            return index === arr.findIndex(o => o.batchCode === obj.batchCode);
          });
          let eee = uniqueArray.map(function (ele) {
            //(ele)
            return {
              value: ele.batchCode,
              label: ele.batchCode,
            };
          });
          // setAllStudents(eee);
          setBatchData(eee);
        })
        .catch(y => {
          if (y?.response?.status === 403) {
            handleError(navigate, socket);
          }
          //(y);
          //(y);
        });
    }
    fetchData();
  };
  // //(batchData);

  let FetchGroup = () => {
    setOpenmodal(true);
    setGroupSelected(true);
    async function fetchData() {
      await AxiosInstance.get(`users/groups`, {
        headers: {
          Authorization: `Bearer ${user?.TOKEN}`,
        },
      })
        .then(x => {
          let data = x?.data?.GroupDetails;
          //(data);
          const uniqueArray = data.filter((obj, index, arr) => {
            return index === arr.findIndex(o => o.groupCode === obj.groupCode);
          });
          // let count = uniqueArray.length;
          //(uniqueArray);
          let eee = uniqueArray.map(function (ele) {
            //(ele)
            return {
              value: ele.groupCode,
              label: ele.groupCode,
            };
          });

          setGroupData(eee);
        })
        .catch(y => {
          if (y?.response?.status === 403) {
            handleError(navigate, socket);
          }
          //(y);
          //(y);
        });
    }
    fetchData();
  };

  let FetchStudents = () => {
    setOpenmodal(true);
    setStudentSelected(true);
    async function fetchData() {
      let StudentData = await AxiosInstance.get(
        `users/getstudents/?search=${searchQuery}`,
        {
          headers: {
            Authorization: `Bearer ${user.TOKEN}`,
          },
        }
      );
      let Studentdataaa = StudentData?.data?.students;
      let eee = Studentdataaa.map(function (ele) {
        return {
          value: ele._id,
          label: ele.username,
        };
      });
      if (SelectedBatches?.length > 0) {
      } else {
        setStudentData(eee);
      }
      if (searchQuery.length > 0) {
        setStudent(eee);
      }
    }
    fetchData();
  };

  useEffect(() => {
    if (searchQuery.trim() !== "" || searchCleared === true) {
      FetchStudents();
    } else {
      setSearchQuery("");
    }
  }, [searchQuery, searchCleared]);
  const handleMenuOpen = () => {
    setSearchQuery("");
    setSearchCleared(true);
  };
  const handleInputChange = value => {
    setSearchQuery(value);
    setSearchCleared(false);
  };
  const handleChangeStudent = e => {
    if (e.length <= 5) {
      setSelectedBatches(Array.isArray(e) ? e.map(x => x.value) : []);
    } else {
      alert("you can only send 5 Batches at a time");
    }
  };

  let FetchUsers = () => {
    async function fetchData() {
      let UserData = await AxiosInstance.get(
        `/employee/users_coneverstions_list`,
        {
          headers: {
            Authorization: `Bearer ${user.TOKEN}`,
          },
        }
      );

      let converName = UserData?.data?.findUserslist;
      let data = converName.map((ele, ind) => {
        let newid = ele.conversation.map(x => {
          let name = x.username !== user.username && x.username;
          return {
            ...x,
            userid: x.id ? x.id : x.id1,
            // name: name,
          };
        });

        let id = newid.filter(y => {
          return y.userid !== user._id;
        });

        return {
          ...ele,
          Conver: true,
          // conve_id: ele._id,
          user_id: ele.group === false ? id[0].userid : ele._id,
          name: ele.group === false ? id[0].username : "",
        };
      });

      let UserList = await AxiosInstance.get(`/employee/users_list`, {
        headers: {
          Authorization: `Bearer ${user.TOKEN}`,
        },
      });

      let NotConvo = UserList?.data?.UsersList;
      let NoCon = NotConvo.map(x => {
        return {
          ...x,
          user_id: x._id,
          group: false,
        };
      });
      let ConbinedList = [...data, ...NoCon];

      let finalList = ConbinedList.filter((ele, ind, self) => {
        return ind === self.findIndex(obj => obj.user_id === ele.user_id);
      });

      let eee = finalList.map(function (ele) {
        return {
          value: ele,
          // label: ele.username ? ele.username : ele.groupName,
          label:
            ele.Conver === true && ele.group === false
              ? ele.name
              : ele.group === true && ele.Conver === true
              ? ele.groupName
              : ele.username,
        };
      });

      setForwardUser(eee);
      // setStudentData(eee);
      // setStudent(eee);
    }
    fetchData();
  };
  const item123 = [
    <Dropdown.Item onClick={() => HandleForward()}>Forward</Dropdown.Item>,
    <Dropdown.Item onClick={() => HandleReply()}>Reply</Dropdown.Item>,
  ];

  // const items = [
  //   <Dropdown.Item>Forward To</Dropdown.Item>,
  //   <Dropdown.Item key={1} onClick={FetchBatch}>
  //     Batches
  //   </Dropdown.Item>,
  //   <Dropdown.Item key={2} onClick={FetchGroup}>
  //     Groups
  //   </Dropdown.Item>,
  //   <Dropdown.Item
  //     key={3}
  //     onClick={() => {
  //       FetchStudents();
  //       setOpenmodal(true);
  //       setStudentSelected(true);
  //     }}
  //   >
  //     Students
  //   </Dropdown.Item>,
  //   <Dropdown.Item
  //     key={4}
  //     onClick={() => {
  //       FetchUsers();
  //       setOpenmodal(true);
  //       setuserSelected(true);
  //       // handleClose2();
  //     }}
  //   >
  //     Users
  //   </Dropdown.Item>,
  // ];
  const renderIconButton = (props, ref) => {
    return forward ? (
      ""
    ) : (
      <IconButton
        {...props}
        ref={ref}
        icon={<FaAngleDown />}
        circle
        color="white"
        size="10px"
      />
    );
  };

  let handleChangeGroup = e => {
    if (e.length <= 5) {
      setSelectedGroups(Array.isArray(e) ? e.map(x => x.value) : []);
    } else {
      alert("you can only send 5 Groups at a time");
    }
  };
  const selectedOptions = SelectedGroups.map(groupValue => ({
    value: groupValue,
    label: groupValue,
  }));
  // //(selectedOptions);
  const selectedOptionsBatch = SelectedBatches.map(groupValue => ({
    value: groupValue,
    label: groupValue,
  }));
  // //(selectedOptionsBatch);

  let handleChangeStudents = e => {
    setSelectedStudents(Array.isArray(e) ? e.map(x => x.value) : []);
  };

  let currentDate = new Date(); // Get the current date and time
  let year = currentDate.getFullYear(); // Get the year
  let month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Get the month (add 1 because months are 0-based) and format it to have leading zeros if necessary
  let day = String(currentDate.getDate()).padStart(2, "0"); // Get the day of the month and format it with leading zeros if necessary
  let hours = String(currentDate.getHours()).padStart(2, "0"); // Get the hours and format them with leading zeros if necessary
  let minutes = String(currentDate.getMinutes()).padStart(2, "0"); // Get the minutes and format them with leading zeros if necessary

  let currentDateTime = `${year}-${month}-${day} ${hours}:${minutes}`;

  useEffect(() => {
    setBackgroundColor1("");
  }, [particularStudent]);

  let HandleForwordMessage = async e => {
    setOpenmodal(false);
    setBackgroundColor1("");
    e.preventDefault();
    let username = user.username;
    let senderId = user._id;
    let role = user?.role1;

    let mmm = selectedMessages.map(x => {
      return x?.messages.text
        ? {
            text: x.messages.text,
            code: x.messages.code ? x.messages.code : "",
            html: x.html && x.html === true ? true : null,
          }
        : {
            files: x.messages.files,
            code: x.messages.code ? x.messages.code : "",
          };
    });
    let codeName = SelectedBatches.map(x => x);
    let groupName = SelectedGroups.map(x => x);
    let StudentName = SelectedStudents.map(x => x);

    if (batchselected === true) {
      let payload = {
        forwardTo: "batch_group",
        username: username,
        senderId: senderId,
        codelist: codeName,
        role: role,
        index: "0",
        type: "batch",
        forward_msgs: mmm,
      };

      //(payload);
      await AxiosInstance.post("/chat/msg/forward_msg", payload, {
        headers: {
          Authorization: `Bearer ${user.TOKEN}`,
        },
      })
        .then(x => {
          //(x);
          if (x?.status === 200) {
            toast.success("Message sent successfully", {
              position: "top-right",
            });
            setOpenmodal(false);
            setBatchSelected(false);

            setSelectedBatches([]);
            // setSelectedItemIndexes([]);
            setSelectedGroups([]);
            setSelectedStudents([]);
            setBatchData([]);
            setGroupData([]);
            setStudentData([]);
            setForwardUser([]);
            setReply(false);
            setForward(false);
            setSelectedMessages([]);
          }
        })
        .catch(y => {
          if (y?.response?.status === 403) {
            handleError(navigate, socket);
          }
          //(y);
        });
    }

    if (groupselected === true) {
      //("groupppppppppppppppppppppp");
      let payload = {
        forwardTo: "batch_group",
        username: username,
        senderId: senderId,
        codelist: groupName,
        role: role,
        index: "0",
        type: "group",
        forward_msgs: mmm,
      };

      //(payload);
      await AxiosInstance.post("/chat/msg/forward_msg", payload, {
        headers: {
          Authorization: `Bearer ${user.TOKEN}`,
        },
      })
        .then(x => {
          //(x);
          if (x?.status === 200) {
            toast.success("Message sent successfully", {
              position: "top-right",
            });
            setOpenmodal(false);
            setGroupSelected(false);

            setSelectedBatches([]);
            // setSelectedItemIndexes([]);
            setSelectedGroups([]);
            setSelectedStudents([]);
            setBatchData([]);
            setGroupData([]);
            setStudentData([]);
            setForwardUser([]);
            setReply(false);
            setForward(false);
            setSelectedMessages([]);
          }
        })
        .catch(y => {
          if (y?.response?.status === 403) {
            handleError(navigate, socket);
          }
          //(y);
        });
    }

    // studentselected
    if (studentselected === true) {
      //("student");
      let payload = {
        forwardTo: "students",
        receiverId: StudentName,
        senderId: senderId,
        role: role,
        index: "0",
        type: "batch",
        forward_msgs: mmm,
      };

      //(payload);
      await AxiosInstance.post("/chat/msg/forward_msg", payload, {
        headers: {
          Authorization: `Bearer ${user.TOKEN}`,
        },
      })
        .then(x => {
          toast.success("Message sent successfully", {
            position: "top-right",
          });
          setOpenmodal(false);
          setSelectedBatches([]);
          setStudentSelected(false);

          setSelectedGroups([]);
          setSelectedStudents([]);
          setBatchData([]);
          setGroupData([]);
          setStudentData([]);
          setForwardUser([]);
          setReply(false);
          setForward(false);
          setSelectedMessages([]);
          // }
        })
        .catch(y => {
          if (y?.response?.status === 403) {
            handleError(navigate, socket);
          }
          //(y);
        });
    }
    if (userselected === true) {
      let codeName = SelectedUsers.filter(x => {
        return x.group === false && x;
      });
      let code = codeName.map(x => {
        return x.user_id;
      });

      let NonRecive = SelectedUsers.filter(x => {
        return x.group === true && x;
      });
      let non = NonRecive.map(x => {
        return x._id;
      });

      let payload = {
        sendername: username,
        senderId: senderId,
        role: role,
        index: "0",
        type: "batch",
        forward_msgs: mmm,
        nonreceiverId: non,
        receiverIds: code,
      };

      await AxiosInstance.post("/employee/users_forwardmsg", payload, {
        headers: {
          Authorization: `Bearer ${user.TOKEN}`,
        },
      })
        .then(x => {
          if (x?.status === 200) {
            toast.success("Message sent successfully", {
              position: "top-right",
            });
            setOpenmodal(false);
            setSelectedBatches([]);
            setuserSelected(false);

            // setSelectedItemIndexes([]);
            setSelectedGroups([]);
            setSelectedStudents([]);
            setBatchData([]);
            setGroupData([]);
            setStudentData([]);
            setSelectedUsers([]);
            setReply(false);
            setForward(false);
            setSelectedMessages([]);
          }
        })
        .catch(y => {
          if (y?.response?.status === 403) {
            handleError(navigate, socket);
          }
          //(y);
        });
    }
  };
  const handleChangeUser = e => {
    if (e.length <= 5) {
      setSelectedUsers(
        Array.isArray(e)
          ? e.map(
              x =>
                // //(x)
                x.value
              // x.value
            )
          : []
      );
    } else {
      alert("you can only send 5 Batches at a time");
    }
  };
  let handlePollAnswer = e => {
    async function fetchData() {
      setLoading(true);

      await AxiosInstance.get(`/employee/stu_getpoll?poll_id=${e}`, {
        headers: {
          Authorization: `Bearer ${user?.TOKEN}`,
        },
      })
        .then(x => {
          let data = x?.data;
          let eee = x?.data?.voters[0]?.votefor?.map(x => x);

          let lll = data?.options?.map(outerOption => {
            return {
              ...outerOption,
              vote_type: data?.vote_type,
              isMatch:
                eee != undefined &&
                eee?.some(
                  voterOption =>
                    voterOption?.options_id === outerOption?.options_id
                ),
            };
          });

          let matching = lll?.filter(x => {
            return x?.isMatch === true;
          });
          setPollData(lll);
          setMainData(data);

          setSelectedLabels(matching);

          setLoading(false);
        })
        .catch(y => {
          if (y?.response?.status === 403) {
            handleError(navigate, socket);
          }
        });
    }
    fetchData();
  };
  let handlePollView = e => {
    async function fetchData() {
      await AxiosInstance.get(`/employee/users_pollviewvotes?poll_id=${e}`, {
        headers: {
          Authorization: `Bearer ${user?.TOKEN}`,
        },
      })
        .then(x => {
          let data = x?.data;
          let final = data?.polldata?.options;

          // Use filter to remove matching objects from array1
          const updatedArray1 = final.filter(
            obj1 =>
              !data?.voters.some(obj2 => obj1.options_id === obj2.options_id)
          );
          const voterOptions = data?.voters?.map(voter => voter._id);

          // Filter updatedArray1 to remove objects with 'option' values that are also in voterOptions
          const filteredArray = updatedArray1.filter(
            obj => !voterOptions.includes(obj.options_id)
          );
          // Concatenate array2 to updatedArray1
          const finalArray = filteredArray.concat(data?.voters);

          // Update the state with the modified array
          setViewOptions(finalArray);
          setViewPollData(data);
        })
        .catch(y => {
          if (y?.response?.status === 403) {
            handleError(navigate, socket);
          }
        });
    }
    fetchData();
  };

  const handleLabelClick = option => {
    if (
      !selectedLabels.some(
        selected => selected.options_id === option.options_id
      )
    ) {
      setSelectedLabels([...selectedLabels, option]);
    } else {
      setSelectedLabels(
        selectedLabels.filter(
          selected => selected.options_id !== option.options_id
        )
      );
    }
  };
  let handleLabelClickRadio = option => {
    setSelectedLabels([option]);
  };
  function stopPropagation(e) {
    //(e);
    e.stopPropagation(); // Stop the event from propagating to the outer div
  }

  let HandleAnswerPoll = () => {
    let payload = {
      pollid: maindata._id,
      userdata: {
        userid: user?._id,
        username: user?.username,
        number: user?.number,
        role: user?.role1,
        votefor: selectedLabels,
      },
    };
    if (selectedLabels?.length === 0) {
      toast.error("Select any option", { position: "top-right" });
    } else {
      async function students() {
        await AxiosInstance.post(`/employee/vote_poll`, payload, {
          headers: {
            Authorization: `Bearer ${user.TOKEN}`,
          },
        })
          .then(x => {
            console?.log(x);
          })
          .catch(x => {
            // console?.log(x);
            if (x?.response?.status === 403) {
              handleError(navigate, socket);
            }
          })
          .finally(() => {
            setOpenPoll(false);
          });
      }
      students();
    }
  };

  function linkifyHtmlContent(text) {
    // Process the text to convert URLs into clickable links
    const linkifyOptions = {
      defaultProtocol: "https",
      target: "_blank", // Open links in a new tab
      rel: "noopener noreferrer", // For security
    };

    return linkifyHtml(text, linkifyOptions);
  }

  function linkify(text) {
    const urlRegex =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    const url = text.match(urlRegex);

    function openInNewTab(url) {
      return (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {url}
        </a>
      );
    }

    return (
      <Linkify componentDecorator={openInNewTab}>
        <p className={Styles.messageText}>
          {message?.messages?.text} <br />
          {(message.broadcastchat && message.forwarded) || message.forwarded ? (
            <span className={Styles.broadcast}>
              <GiWideArrowDunk />
            </span>
          ) : message.broadcastchat ? (
            <span className={Styles.broadcast}>
              <HiSpeakerphone />
            </span>
          ) : (
            ""
          )}
        </p>
      </Linkify>
    );
  }

  //like share dislike
  let [likeshareSelected, setLikeShareSelected] = useState([]);
  let [selectedData, setSelectedData] = useState(null);

  let handleViewLikeShare = e => {
    setLikeshareModal(true);
    async function fetchData() {
      await AxiosInstance.get(
        `/users/share_like_selected?post_id=${e?.share.usershare_id}`
      )
        .then(x => {
          setLikeShareSelected(x?.data?.post_data);
          let data = x?.data?.post_data?.student_action;
          if (data.length > 0) {
            const filteredStudents = data.filter(
              student => student.studentid === particularStudent?.studentId
            );

            setSelectedData(filteredStudents[0]);
          }
        })
        .catch(y => {
          console.log(y);
        });
    }
    fetchData();
  };

  let [likeshareModal, setLikeshareModal] = useState(false);
  const handleLikeShareOpenModal = () => {
    setLikeshareModal(true);
  };
  const handleLikeShareCloseModal = () => {
    setLikeshareModal(false);
  };

  const [selected, setSelected] = useState([]);
  //Like-------------------------------
  const [openModalLike, setOpenModalLike] = useState(false);
  const handleOpenModalLike = () => {
    let data = likeshareSelected?.student_action?.filter(ele => {
      return ele.like > 0;
    });
    setSelected(data);
    setOpenModalLike(true);
    setLikeshareModal(false);
  };

  const handleCloseModalLike = () => {
    setOpenModalLike(false);
    setSelected([]);
  };
  //Dislike-------------------------------
  const [openModalDislike, setOpenModalDislike] = useState(false);
  const handleOpenModalDislike = () => {
    let data = likeshareSelected?.student_action?.filter(ele => {
      return ele.dislike > 0;
    });

    setSelected(data);
    setOpenModalDislike(true);
    setLikeshareModal(false);
  };
  const handleCloseModalDislike = () => {
    setOpenModalDislike(false);
    setSelected([]);
  }; //Share-------------------------------

  const [openModalShare, setOpenModalShare] = useState(false);

  const handleOpenModalShare = () => {
    let data = likeshareSelected?.student_action?.filter(ele => {
      return ele.share > 0;
    });
    setSelected(data);
    setOpenModalShare(true);
    setLikeshareModal(false);
  };
  const handleCloseModalShare = () => {
    setOpenModalShare(false);
    setSelected([]);
  };

  return loadingmsg === true ? (
    <div className={Styles.loader}>
      {" "}
      <Rings color="#00BFFF" height={200} width={200} />
    </div>
  ) : (
    <>
      <div
        className={
          message?.messages?.text !== ""
            ? `${Styles.Messageeee}`
            : `${Styles.Emptymsg}`
        }
      >
        <div
          className={
            own
              ? `${Styles.sendermsg}`
              : message?.sender === particularStudent?.studentId
              ? `${Styles.recivermsg}`
              : `${Styles.displaynone}`
          }
        >
          {/* likeshare Modal */}
          <MuiModal
            open={likeshareModal}
            onClose={handleLikeShareCloseModal}
            // closeAfterTransition
          >
            {/* <Fade in={openPreviewModal}> */}
            <Box sx={styleModal2}>
              <MuiIconButton
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  zIndex: "2222",
                }}
                onClick={handleLikeShareCloseModal}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={22}
                  height={22}
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="crimson"
                    d="M20 6.91L17.09 4L12 9.09L6.91 4L4 6.91L9.09 12L4 17.09L6.91 20L12 14.91L17.09 20L20 17.09L14.91 12z"
                  ></path>
                </svg>
              </MuiIconButton>
              <Card key={message.id} sx={{ marginBottom: "16px" }}>
                <CardContent>
                  {likeshareSelected?.html ? (
                    <Linkify
                      componentDecorator={(
                        decoratedHref,
                        decoratedText,
                        key
                      ) => (
                        <a
                          href={decoratedHref}
                          target="_blank"
                          rel="noopener noreferrer"
                          key={key}
                        >
                          {decoratedText}
                        </a>
                      )}
                    >
                      <pre
                        dangerouslySetInnerHTML={{
                          __html: linkifyHtmlContent(
                            message?.messages?.text?.slice(0, 85) || ""
                          ),
                        }}
                        className={Styles.textEditStyle}
                      />
                    </Linkify>
                  ) : (
                    <Typography variant="body1" gutterBottom>
                      {likeshareSelected?.message?.text?.slice(0, 85)}
                    </Typography>
                  )}

                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                      fontSize: "0.875rem",
                      color: "grey",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                    //
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={30}
                      height={30}
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill={
                          selectedData && selectedData?.like > 0
                            ? "#1877F2"
                            : "lightgray"
                        }
                        d="M23 10a2 2 0 0 0-2-2h-6.32l.96-4.57c.02-.1.03-.21.03-.32c0-.41-.17-.79-.44-1.06L14.17 1L7.59 7.58C7.22 7.95 7 8.45 7 9v10a2 2 0 0 0 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73zM1 21h4V9H1z"
                      ></path>
                    </svg>
                    <span
                      onClick={() => handleOpenModalLike()}
                      style={{ color: "blue", borderBottom: "1px solid" }}
                    >
                      {" "}
                      Like - {likeshareSelected?.like}
                    </span>
                  </Typography>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                      fontSize: "0.875rem",
                      color: "grey",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={30}
                      height={30}
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill={
                          selectedData && selectedData?.dislike > 0
                            ? "#1877F2"
                            : "lightgray"
                        }
                        d="M19 15h4V3h-4m-4 0H6c-.83 0-1.54.5-1.84 1.22l-3.02 7.05c-.09.23-.14.47-.14.73v2a2 2 0 0 0 2 2h6.31l-.95 4.57c-.02.1-.03.2-.03.31c0 .42.17.79.44 1.06L9.83 23l6.58-6.59c.37-.36.59-.86.59-1.41V5a2 2 0 0 0-2-2"
                      ></path>
                    </svg>
                    <span
                      onClick={() => handleOpenModalDislike()}
                      style={{ color: "blue", borderBottom: "1px solid" }}
                    >
                      {" "}
                      Dislike - {likeshareSelected?.dislike}
                    </span>
                  </Typography>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                      fontSize: "0.875rem",
                      color: "grey",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={30}
                      height={30}
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill={
                          selectedData && selectedData?.share > 0
                            ? "#1877F2"
                            : "lightgray"
                        }
                        d="M307 34.8c-11.5 5.1-19 16.6-19 29.2v64H176C78.8 128 0 206.8 0 304c0 113.3 81.5 163.9 100.2 174.1c2.5 1.4 5.3 1.9 8.1 1.9c10.9 0 19.7-8.9 19.7-19.7c0-7.5-4.3-14.4-9.8-19.5c-9.4-8.9-22.2-26.4-22.2-56.8c0-53 43-96 96-96h96v64c0 12.6 7.4 24.1 19 29.2s25 3 34.4-5.4l160-144c6.7-6.1 10.6-14.7 10.6-23.8s-3.8-17.7-10.6-23.8l-160-144a31.76 31.76 0 0 0-34.4-5.4"
                      ></path>
                    </svg>
                    <span
                      onClick={() => handleOpenModalShare()}
                      style={{ color: "blue", borderBottom: "1px solid" }}
                    >
                      {" "}
                      Share - {likeshareSelected?.share}
                    </span>
                  </Typography>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{ fontSize: "1rem", color: "grey" }}
                  >
                    Total students - {likeshareSelected?.total_students}
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          </MuiModal>
          {/* Modal for Likes displaying names */}
          <Modal open={openModalLike} onClose={handleCloseModalLike}>
            <Modal.Header>
              <Modal.Title>
                <Box
                  sx={{
                    borderBottom: "1px solid #ddd",
                    paddingBottom: "10px",
                    marginBottom: "20px",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#f16f1d",
                      fontWeight: "bold",
                      fontSize: "18px",
                      textAlign: "center",
                    }}
                  >
                    Students liked message
                  </Typography>
                </Box>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {selected?.length > 0 ? (
                selected?.map((name, index) => (
                  <Typography key={name.username} variant="body2" gutterBottom>
                    {name.username}
                  </Typography>
                ))
              ) : (
                <Typography variant="body2" color="text.secondary">
                  No names available.
                </Typography>
              )}
            </Modal.Body>
          </Modal>
          {/* Modal for Dislikes displaying names */}
          <Modal open={openModalDislike} onClose={handleCloseModalDislike}>
            <Modal.Header>
              <Modal.Title>
                <Box
                  sx={{
                    borderBottom: "1px solid #ddd",
                    paddingBottom: "10px",
                    marginBottom: "20px",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#f16f1d",
                      fontWeight: "bold",
                      fontSize: "18px",
                      textAlign: "center",
                    }}
                  >
                    Students disliked message
                  </Typography>
                </Box>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {selected?.length > 0 ? (
                selected?.map((name, index) => (
                  <Typography key={name.username} variant="body2" gutterBottom>
                    {name.username}
                  </Typography>
                ))
              ) : (
                <Typography variant="body2" color="text.secondary">
                  No names available.
                </Typography>
              )}
            </Modal.Body>
          </Modal>
          {/* Modal for shared displaying names */}
          <Modal open={openModalShare} onClose={handleCloseModalShare}>
            <Modal.Header>
              <Modal.Title>
                <Box
                  sx={{
                    borderBottom: "1px solid #ddd",
                    paddingBottom: "10px",
                    marginBottom: "20px",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#f16f1d",
                      fontWeight: "bold",
                      fontSize: "18px",
                      textAlign: "center",
                    }}
                  >
                    Shared message list
                  </Typography>
                </Box>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {selected?.length > 0 ? (
                selected?.map((name, index) => (
                  <Typography key={name.username} variant="body2" gutterBottom>
                    {name.username} - <span>{name.share}</span>
                  </Typography>
                ))
              ) : (
                <Typography variant="body2" color="text.secondary">
                  No names available.
                </Typography>
              )}
            </Modal.Body>
          </Modal>{" "}
          <MuiModal
            open={open}
            onClose={handleClose}
            aria-labelledby="image-modal"
            aria-describedby="image-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                maxWidth: "100%",
                maxHeight: "100%",
                bgcolor: "background.paper",
                boxShadow: 24,
                outline: "none",
                overflow: "hidden",
                cursor: zoomLevel > 1 ? "grab" : "auto",
              }}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseLeave}
            >
              <MuiIconButton
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  zIndex: "2222",
                }}
                onClick={handleClose}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={22}
                  height={22}
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="crimson"
                    d="M20 6.91L17.09 4L12 9.09L6.91 4L4 6.91L9.09 12L4 17.09L6.91 20L12 14.91L17.09 20L20 17.09L14.91 12z"
                  ></path>
                </svg>
              </MuiIconButton>
              <img
                ref={imgRef}
                src={previewImage}
                alt="Modal Content"
                style={{
                  maxWidth: "90%",
                  maxHeight: "90%",
                  objectFit: "contain",
                  display: "block",
                  margin: "0 auto",
                  transform: `scale(${zoomLevel}) translate(${offsetX}px, ${offsetY}px)`,
                  transition: "transform 0.3s ease",
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  flexDirection: "column",
                  top: "35px",
                  right: "-3px",
                }}
              >
                <MuiIconButton variant="secondary" onClick={handleZoomOut}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={20}
                    height={20}
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#555"
                      d="M9 2a7 7 0 0 1 7 7c0 1.57-.5 3-1.39 4.19l.8.81H16l6 6l-2 2l-6-6v-.59l-.81-.8A6.9 6.9 0 0 1 9 16a7 7 0 0 1-7-7a7 7 0 0 1 7-7M5 8v2h8V8z"
                    ></path>
                  </svg>
                </MuiIconButton>
                <MuiIconButton variant="secondary" onClick={handleZoomIn}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={20}
                    height={20}
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#555"
                      d="M9 2a7 7 0 0 1 7 7c0 1.57-.5 3-1.39 4.19l.8.81H16l6 6l-2 2l-6-6v-.59l-.81-.8A6.9 6.9 0 0 1 9 16a7 7 0 0 1-7-7a7 7 0 0 1 7-7M8 5v3H5v2h3v3h2v-3h3V8h-3V5z"
                    ></path>
                  </svg>
                </MuiIconButton>
              </Box>
            </Box>
          </MuiModal>
          <Modal
            onClose={handleClose2}
            // backdrop={backdrop}
            open={openmodal2}
            size="xs"
          >
            <Modal.Header>
              <Modal.Title>
                <h4>Forward To </h4>
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div
                className={Styles.ListForward}
                onClick={() => {
                  FetchBatch();
                  handleClose2();
                }}
              >
                Batches
              </div>
              <div
                className={Styles.ListForward}
                onClick={() => {
                  FetchGroup();
                  handleClose2();
                }}
              >
                Groups
              </div>
              <div
                className={Styles.ListForward}
                onClick={() => {
                  FetchStudents();
                  setOpenmodal(true);
                  setStudentSelected(true);
                  handleClose2();
                }}
              >
                Students
              </div>
              <div
                className={Styles.ListForward}
                onClick={() => {
                  FetchUsers();
                  setOpenmodal(true);
                  setuserSelected(true);
                  handleClose2();
                }}
              >
                {" "}
                Users
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            open={openmodal}
            onClose={handleCloseModal}
            // onEntered={handleEntered}
            size="lg"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // height: "500px",
              // width: "58%",
            }}
          >
            <Modal.Header>
              {/* <Modal.Title>Forward Messages To</Modal.Title> */}
            </Modal.Header>
            <Modal.Body
              style={{
                overflow: "unset",
                maxheight: "none",
              }}
            >
              {
                <div className={Styles.chatMenuWrapper1}>
                  <div className={Styles.ConversationBlock1}>
                    <>
                      {batchData && batchData?.length > 0 ? (
                        <>
                          <h4>Forward Messages To Batches</h4>
                          <Select
                            closeMenuOnSelect={false}
                            isMulti
                            name="addStudentstogroup"
                            options={batchData}
                            onChange={handleChangeStudent}
                            onMenuScrollToBottom
                            value={selectedOptionsBatch}
                          />
                        </>
                      ) : groupData && groupData.length > 0 ? (
                        <>
                          <h4>Forward Messages To Groups</h4>
                          <Select
                            closeMenuOnSelect={false}
                            isMulti
                            name="addStudentstogroup"
                            options={groupData}
                            onChange={handleChangeGroup}
                            onMenuScrollToBottom
                            value={selectedOptions}
                          />
                        </>
                      ) : studentData && studentData.length > 0 ? (
                        <>
                          <h4>Forward Messages To Students</h4>
                          <Select
                            onMenuOpen={handleMenuOpen}
                            closeMenuOnSelect={false}
                            isMulti
                            onInputChange={handleInputChange}
                            name="addStudentstogroup"
                            options={studentData}
                            onChange={handleChangeStudents}
                            onMenuScrollToBottom
                          />
                        </>
                      ) : forwardUser && forwardUser.length > 0 ? (
                        <>
                          <>
                            <h4>.Forward Messages To Users. </h4>
                            <Select
                              closeMenuOnSelect={false}
                              isMulti
                              name="addStudentstogroup"
                              options={forwardUser}
                              onChange={handleChangeUser}
                              onMenuScrollToBottom
                            />
                          </>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  </div>

                  <div className={Styles.loadmoreBlock}></div>
                </div>
              }
            </Modal.Body>
            <Modal.Footer>
              <div className={styles.sendbtnfile}>
                <button id={styles.filesubmit}>
                  <FiSend onClick={HandleForwordMessage} />
                </button>
              </div>
            </Modal.Footer>
          </Modal>
          <Modal
            onClose={handleClosepoll}
            // backdrop={backdrop}
            open={openPoll}
            size="lg"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Modal.Header>
              <Modal.Title>Poll</Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                overflow: "unset",
                maxheight: "none",
              }}
            >
              <aside className={Styles.MainContainer}>
                <h4 className={Styles.TextAlign}>{maindata?.poll_question}</h4>
                {maindata?.vote_type ? <span>Select one or more</span> : ""}
                {loading ? (
                  ""
                ) : (
                  <form>
                    {pollData?.map((ele, ind) => {
                      return (
                        <div
                          key={ele?.options_id}
                          className={Styles.polloption}
                        >
                          {" "}
                          {maindata?.vote_type === false ? (
                            <>
                              <input
                                type="radio"
                                name="poll_option"
                                value={ele?.options_id}
                                id={ele?.options_id}
                                defaultChecked={ele?.isMatch === true}
                              />
                              <label
                                htmlFor={ele?.options_id}
                                onClick={() => handleLabelClickRadio(ele)}
                                className={Styles.TextAlign}
                              >
                                {ele?.option}
                              </label>
                            </>
                          ) : (
                            <>
                              <input
                                type="checkbox"
                                name="poll_option"
                                value={ele?.options_id}
                                id={ele?.options_id}
                                defaultChecked={ele?.isMatch === true}
                              />
                              <label
                                htmlFor={ele?.options_id}
                                onClick={() => handleLabelClick(ele)}
                                className={Styles.TextAlign}
                              >
                                {ele?.option}
                              </label>
                            </>
                          )}
                        </div>
                      );
                    })}
                  </form>
                )}
              </aside>
            </Modal.Body>
            <Modal.Footer>
              <button
                className={Styles.SubmitPoll}
                onClick={() => HandleAnswerPoll()}
              >
                <IoSendSharp />
              </button>
            </Modal.Footer>
          </Modal>
          <Modal
            onClose={handleClosepollView}
            // backdrop={backdrop}
            open={openPollView}
            size="lg"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Modal.Header>
              <Modal.Title>Poll Details</Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                overflow: "unset",
                maxheight: "none",
              }}
            >
              <div className={Styles.ViewPollBlock}>
                <h4 className={Styles.TextAlign}>
                  {viewPollData?.polldata?.poll_question}
                </h4>
                {viewOptions?.map((ele, ind) => {
                  return (
                    <aside className={Styles.options}>
                      <div className={Styles.Voters}>
                        <h6 className={Styles.TextAlign}>{ele?.option}</h6>
                        {ele?.voters ? (
                          <span>Votes- {ele?.voters?.length}</span>
                        ) : (
                          <span>Votes- 0</span>
                        )}
                      </div>
                      {ele.voters
                        ? ele.voters.map(x => {
                            return <p>{x.username}</p>;
                          })
                        : ""}
                    </aside>
                  );
                })}
              </div>
            </Modal.Body>
          </Modal>
          <main
            style={{ backgroundColor: backgroundColor1 }}
            onClick={handleClick}
          >
            <div>
              {message.status ? (
                <div className={Styles.StatusText}>
                  Status.{" "}
                  {message.status.message.text ? (
                    message.status.message.text?.slice(0, 85)
                  ) : message?.status?.message?.files?.length > 0 ? (
                    <>
                      {(() => {
                        switch (message?.status?.message?.files[0]?.mimetype) {
                          case "image/jpg":
                          case "image/jpeg":
                          case "image/avif":
                          case "image/png":
                          case "image/webp":
                          case "image/tiff":
                          case "image/bmp":
                            return (
                              <>
                                <img
                                  src={imageUrl?.url}
                                  alt=""
                                  style={{
                                    width: "100%",
                                    height: "100px",
                                    borderRadius: "5px",
                                    display: "flex",
                                  }}
                                />{" "}
                              </>
                            );
                        }
                      })()}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              ) : message?.replayback &&
                Object.keys(message?.replayback).length > 0 ? (
                <div className={Styles.StatusText}>
                  Reply-{" "}
                  {message?.replayback?.message &&
                  message?.replayback?.message !== "" ? (
                    message?.replayback?.message.slice(0, 85)
                  ) : message?.replayback?.file?.length > 0 ? (
                    <>
                      {(() => {
                        switch (message?.replayback?.file[0]?.mimetype) {
                          case "image/jpg":
                          case "image/jpeg":
                          case "image/avif":
                          case "image/png":
                          case "image/webp":
                          case "image/tiff":
                          case "image/bmp":
                            return (
                              <>
                                <img
                                  src={replyimgUrl?.url}
                                  alt=""
                                  style={{
                                    width: "100%",
                                    height: "100px",
                                    borderRadius: "5px",
                                    display: "flex",
                                  }}
                                  onClick={e => {
                                    stopPropagation(e);
                                    handleImage(replyimgUrl?.url);
                                  }}
                                />{" "}
                              </>
                            );
                          default:
                            return (
                              <button className={Styles.btndownload1}>
                                {" "}
                                {message?.replayback?.file[0]?.originalname}
                              </button>
                            );
                        }
                      })()}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
            {message?.poll && message?.poll !== null ? (
              <div className={Styles.messageTop}>
                <div className={Styles.Pollhead}> poll</div>
                <h6 className={Styles.TextAlign}>
                  {message?.poll?.poll_question}
                </h6>
                <div>
                  {currentDateTime <= message?.poll?.endTime ? (
                    <button
                      className={Styles.AnswerPoll}
                      onClick={e => {
                        stopPropagation(e);
                        handlePollAnswer(message?.poll?._id);
                        handleOpenpoll();
                      }}
                    >
                      Answer
                    </button>
                  ) : (
                    <Typography
                      variant="caption"
                      sx={{
                        fontSize: "12 px",
                        color: "red",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Poll Expired
                    </Typography>
                  )}

                  <button
                    className={Styles.AnswerView}
                    onClick={e => {
                      stopPropagation(e);
                      handlePollView(message?.poll?._id);
                      handleOpenpollView();
                    }}
                  >
                    View
                  </button>
                </div>
                {currentDateTime < message?.poll?.endTime && (
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: "10px",
                      color: "#5e4848",
                    }}
                  >
                    Ends at {new Date(message?.poll?.endTime).toLocaleString()}
                  </Typography>
                )}
              </div>
            ) : (
              <div className={Styles.messageTop}>
                {message?.messages?.text ? (
                  message?.messages?.text && message?.html ? (
                    <Linkify
                      componentDecorator={(
                        decoratedHref,
                        decoratedText,
                        key
                      ) => (
                        <a
                          href={decoratedHref}
                          target="_blank"
                          rel="noopener noreferrer"
                          key={key}
                        >
                          {decoratedText}
                        </a>
                      )}
                    >
                      <pre
                        dangerouslySetInnerHTML={{
                          __html: linkifyHtmlContent(
                            message?.messages?.text || ""
                          ),
                        }}
                        className={Styles.textEditStyle}
                      />
                    </Linkify>
                  ) : (
                    linkify(message?.messages?.text)
                  )
                ) : message?.messages?.files?.length > 0 ? (
                  <>
                    {(() => {
                      switch (message?.messages?.files[0]?.mimetype) {
                        case "image/jpg":
                        case "image/jpeg":
                        case "image/avif":
                        case "image/png":
                        case "image/webp":
                        case "image/tiff":
                        case "image/bmp":
                          return (
                            <>
                              <img
                                src={imageUrl?.url}
                                alt=""
                                style={{
                                  width: "200px",
                                  height: "130px",
                                  borderRadius: "5px",
                                  display: "flex",
                                }}
                                onClick={e => {
                                  stopPropagation(e);
                                  handleImage(imageUrl?.url);
                                }}
                              />{" "}
                              {message?.messages?.files[0]?.text ? (
                                <p>{message?.messages?.files[0]?.text}</p>
                              ) : null}
                              <button
                                className={Styles.btndownload}
                                onClick={() =>
                                  download(
                                    imageUrl?.url,
                                    message?.messages?.files[0]?.originalname,
                                    message?.messages?.files[0].filename
                                  )
                                }
                              >
                                download
                              </button>
                              {message.broadcastchat ? (
                                <>
                                  <div>
                                    <span className={Styles.broadcast}>
                                      <HiSpeakerphone />
                                      {message.broadcastchat}
                                    </span>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </>
                          );

                        case "video/mp4":
                        case "video/x-matroska":
                          return (
                            <button className={Styles.btndownload1}>
                              {" "}
                              {message?.messages?.files[0]?.originalname}
                              <span
                                className={Styles.Download}
                                onClick={() =>
                                  download(
                                    imageUrl?.url,
                                    message?.messages?.files[0]?.originalname,
                                    message?.messages?.files[0].filename
                                  )
                                }
                              >
                                Click to download
                              </span>
                            </button>
                          );
                        default:
                          return (
                            <button className={Styles.btndownload1}>
                              {" "}
                              {message?.messages?.files[0]?.originalname}
                              <span
                                className={Styles.Download}
                                onClick={() =>
                                  download(
                                    imageUrl?.url,
                                    message?.messages?.files[0]?.originalname,
                                    message?.messages?.files[0].filename
                                  )
                                }
                              >
                                Click to download
                              </span>
                            </button>
                          );
                        case "application/docx":
                        case "application/doc":
                        case "application/msword":
                        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                        case "application/vnd.openxmlformats-officedocument.wordprocessingml.template":
                        case "application/vnd.ms-word.document.macroEnabled.12":
                        case "application/vnd.ms-excel":
                        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                        case "application/vnd.openxmlformats-officedocument.spreadsheetml.template":
                        case "application/vnd.ms-excel.sheet.macroEnabled.12":
                        case "application/vnd.ms-powerpoint":
                        case "application/xlsx":
                        case "application/pptx":
                        case "application/octet-stream":
                        case "text/plain":
                        case "text/html":
                        case "application/apk":
                        case "application/json":
                          return (
                            <button className={Styles.btndownload1}>
                              {" "}
                              {message?.messages?.files[0]?.originalname}
                              <span
                                className={Styles.Download}
                                onClick={() =>
                                  download(
                                    imageUrl?.url,
                                    message?.messages?.files[0]?.originalname,
                                    message?.messages?.files[0].filename
                                  )
                                }
                              >
                                Click to download
                              </span>
                            </button>
                          );
                        case "audio/mpeg":
                        case "audio/mp3":
                        case "audio/webm":
                        case "application/wav":
                        case "video/webm":
                        case "audio/wav":
                          return (
                            <audio
                              src={imageUrl?.url}
                              controls
                              style={{ width: "250px", height: "40px" }}
                            ></audio>
                          );

                        case "application/pdf":
                        case "application/x-pdf":
                        case "application/x-gzpdf":
                        case "applications/vnd.pdf":
                        case "application/acrobat":
                        case "application/x-google-chrome-pdf":
                        case "text/pdf":
                        case "text/x-pdf":
                        case "text/csv":
                        case "application/zip":
                        case "application/x-zip":
                        case "application/octet-stream":
                        case "application/x-zip-compressed":
                          return (
                            <>
                              <button className={Styles.btndownload1}>
                                {" "}
                                {message?.messages?.files[0]?.originalname}
                                <span
                                  className={Styles.Download}
                                  onClick={() =>
                                    download(
                                      imageUrl?.url,
                                      message?.messages?.files[0]?.originalname,
                                      message?.messages?.files[0].filename
                                    )
                                  }
                                >
                                  Click to download
                                </span>
                              </button>
                            </>
                          );
                      }
                    })()}
                  </>
                ) : (
                  <h1>oops</h1>
                )}
              </div>
            )}
            <div className={Styles.messageBottom}>
              {/* <Moment fromNow>{message.createdAt}</Moment> */}
              {Date.now ? (
                <Moment format="MMM DD - h:mm a">{message?.createdAt}</Moment>
              ) : (
                <Moment format="MMM DD YYYY h A">{message?.createdAt}</Moment>
              )}
              <span className={Styles.BatchGroup}>
                {message?.broadcastchat
                  ? message?.broadcastchat
                  : message?.messages?.code}
              </span>
              {own ? (
                message?.tick == false ? (
                  <FcCheckmark />
                ) : (
                  <IoCheckmarkDoneSharp
                    style={{ color: " rgb(67, 160, 71)" }}
                  />
                )
              ) : (
                ""
              )}
              {backgroundColor1 === "rgb(0 0 0 / 12%)" && !forward ? (
                // forwardTrue === false
                <span
                  className={Styles.ForwardMsgBlock}
                  onClick={e => stopPropagation(e)}
                >
                  {" "}
                  <Dropdown renderToggle={renderIconButton} placement="leftEnd">
                    {item123}
                  </Dropdown>
                </span>
              ) : backgroundColor1 === "rgb(0 0 0 / 12%)" && forward ? (
                <span
                  onClick={e => stopPropagation(e)}
                  className={Styles.ForwardMsgBlock}
                >
                  <div onClick={handleOpen2} className={Styles.ForwardBlock}>
                    {" "}
                    <IoIosShareAlt />
                  </div>
                </span>
              ) : (
                ""
              )}
            </div>
            {own && message?.share ? (
              <div
                style={{
                  gap: "3px",
                  display: "flex",
                  alignItems: "flex-end",
                  marginTop: "4px",
                }}
              >
                <span
                  onClick={e => {
                    handleViewLikeShare(message);
                    stopPropagation(e);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    viewBox="0 0 128 128"
                  >
                    <path
                      fill="#eee"
                      d="M63.57 25C44.91 24.83 14.64 32.42.91 71.67c0 0-1.64 6.06.58 8.67c2.5 2.92 6.3 1.78 6.3 1.78S26.87 108 63.54 107.75c36.68-.25 52.98-23.83 52.98-23.83s3.86 2.75 7.19 1.08c2.7-1.35 3.91-3.88 3.95-8.33c.11-11.24-20.17-51.28-64.09-51.67"
                    ></path>
                    <path
                      fill="#fff"
                      d="M115.68 68.45c0 16.23-23.73 29.37-53.08 29.37c-29.29 0-53.08-13.14-53.08-29.37S33.31 39.08 62.6 39.08c29.35 0 53.08 13.14 53.08 29.37"
                    ></path>
                    <path
                      fill="#2f2f2f"
                      d="M121.56 64C110.91 42.17 89.9 29.16 68.32 27.38c-9.27-.74-18.08.35-26.15 3.1c-14.8 5.08-27.08 15.77-35.09 31c-2.24 4.23-5.04 9.1-5.24 14.04c-.09 2.29.66 4.22 3.03 4.73c3.76.82 5.79-2.46 8.18-4.75c1.47-1.42 3.11-3.45 4.85-4.5c1.19-.72 4.01-1.21 5.28-.46c3.66 2.21 4.47 8.31 5.99 12.02c8.76 21.31 39.48 23.75 54.96 8.57c3.87-3.77 7.73-9.96 9.04-15.28c.62-2.52-.07-11.18 3.78-11.79c2.59-.39 5.4 2.45 7.27 3.86c4.4 3.36 10.96 15.82 17.22 15.33c1.68-.12 3.8-2.71 4.15-4.19c1.16-4.89-3-12.94-4.03-15.06"
                    ></path>
                    <path
                      fill="#fff"
                      d="M57.34 61.27c-2.44 6.63-12.64 9.34-17.38 3.89c-3.72-4.26-2.34-10.04 1.59-13.55c7.1-6.34 18.95 1.09 15.79 9.66"
                    ></path>
                  </svg>
                </span>
              </div>
            ) : (
              ""
            )}
          </main>
        </div>
      </div>
    </>
  );
};

export default Message;
