import Styles from "./_chat.module.css";
import React, { useState } from "react";
import {
  Modal,
  Box,
  Button,
  TextField,
  Typography,
  List,
  ListItem,
} from "@mui/material";
import SearchFilter from "./SearchFilter";
const StudentName = ({ name, online }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <main>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90vw",
            height: "85vh",
            bgcolor: "background.paper",
            p: 4,
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            borderRadius: 2,
          }}
        >
          <button
            style={{
              background: "transparent",
              position: "absolute",
              right: "5px",
              top: "5px",
            }}
            onClick={() => {
              handleClose();
            }}
            title="Close"
          >
            <svg width={18.54} height={20} viewBox="0 0 1216 1312">
              <path
                fill="crimson"
                d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68"
              ></path>
            </svg>
          </button>

          <SearchFilter particularTrainer={name} />
        </Box>
      </Modal>
      <div style={{ display: "flex" }}>
        <div>
          {online === "online" ? (
            <>
              {" "}
              <p className={Styles.Username}>{name?.trainerName}</p>
              <p id={online === "online" ? Styles.online : Styles.offline}>
                Online
              </p>{" "}
            </>
          ) : (
            <>
              <p className={Styles.Username}>{name?.trainerName}</p>
              <p id={online === "online" ? Styles.online : Styles.offline}>
                Offline
              </p>{" "}
            </>
          )}
        </div>
        <span>These chats are monitored by admin</span>
        <span
          style={{ marginRight: "20px" }}
          onClick={handleOpen}
          title="Search filter"
        >
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20px"
            height="20px"
            viewBox="0 0 512 512"
          >
            <path
              fill="#f57c00"
              d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34M208 336c-70.7 0-128-57.2-128-128c0-70.7 57.2-128 128-128c70.7 0 128 57.2 128 128c0 70.7-57.2 128-128 128"
            ></path>
          </svg> */}
        </span>
      </div>
    </main>
  );
};

export default StudentName;
